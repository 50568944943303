import { SupplierModuleGroupType } from "@/model/Enums";
import { ISupplierModule, SupplierModule } from "@/model/SupplierModule";
import { mapData } from "@/stuff/DataMapping";

export interface ISupplierModuleGroup {
    group: SupplierModuleGroupType;
    modules: Array<ISupplierModule>;
}

export class SupplierModuleGroup implements ISupplierModuleGroup {

    constructor(data?: ISupplierModuleGroup) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplierModuleGroup) {
        mapData(data, { 
            root: () => this,
            modules: () => new SupplierModule()
        });
    }

    group: SupplierModuleGroupType = SupplierModuleGroupType.None;
    modules: Array<ISupplierModule> = [];

}