
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";
    import store from "@/store/store";
    import ApiButton from "@/components/ApiButton.vue";
    import SurveyPageComponent from "@/components/Survey/Page.vue";
    import LegacyDocumentList from "@/components/LegacyDocumentList.vue";
    import MissingResponseSet from "@/components/MissingResponseSet.vue";
    import fileDownload from "@/stuff/FileDownload";
    import apiClient from "@/stuff/ApiClient";
    import dirtyness from "@/stuff/Dirtyness";
    import utils from "@/stuff/Utils";
    import eventBus from "@/stuff/EventBus";
    import { beforeRouteLeave } from "@/router/router";
    import { SupplierModuleGroupType, ResponseSetStatus, TriState } from "@/model/Enums";
    import { Module } from "@/model/Module";
    import { ModuleSearchParameters } from "@/model/ModuleSearchParameters";
    import { ISupplierResponseSet, SupplierResponseSet } from "@/model/SupplierResponseSet";
    import { SurveyQuestionOptions } from "@/model/SurveyQuestionOptions";
    import { IStatusChangedArguments } from "@/model/StatusChangedArguments";
    import { ISupplierModuleGroup, SupplierModuleGroup } from "@/model/SupplierModuleGroup";
    import { SupplierModule } from "@/model/SupplierModule";
    import { ISupplier, Supplier } from "@/model/Supplier";
 
    type CssClasses = {
        [key: string]: boolean;
    };

    @Component({
        components: { ApiButton, SurveyPageComponent, LegacyDocumentList, MissingResponseSet },
        beforeRouteLeave
    })
    export default class Modules extends Vue {

        mounted() {
            store.dispatch("loadUserList");
            this.searchParameters.isForSupplierZone = true;
            this.searchParameters.supplierID = store.state.signedInUser == null ? 0 : +store.state.signedInUser.supplierID;
            this.searchParameters.isDeleted = TriState.False;
            this.refresh();

            // set own reactive property whenever global dirty flag changes
            eventBus.$on("dirtyness-changed", (isDirty: boolean) => {
                this.isDirty = isDirty;
            });

            eventBus.$on("server-data-changed", (what: string, id: number, userID: string) => {
                console.log(`#-#-# SIGNALR - Modules.vue - what=${what} id=${id} userID=${userID}`);
                // todo - if not this user AND is 
            });
        }

        //
        // -- properties
        //

        private supplierResponseSet: SupplierResponseSet | null = null;
        private searchParameters = new ModuleSearchParameters();
        private moduleGroups: Array<ISupplierModuleGroup> = [];
        
        private module: Module|null = null;
        private isDirty: boolean = false; // we need a reactive property AND a global flag - so we have TWO flags (for now)
        private isWaiting: boolean = true;
        
        private supplier: Supplier = new Supplier();

        private get selectedModuleID(): number {
            return this.module ? this.module.id : 0;
        }

        private get isMyModules(): boolean {
            return this.$route.path.indexOf("status") > -1;
        }
        private get pageTitle(): string {
            return this.isMyModules ? "Current Status" : "Modules To Do";
        }
        private get filteredGroups(): Array<ISupplierModuleGroup> {
            return this.moduleGroups.filter(group => {
                if (this.isMyModules && (group.group === SupplierModuleGroupType.Accepted || group.group === SupplierModuleGroupType.Expired || group.group === SupplierModuleGroupType.Legacy))
                    return true;
                if (!this.isMyModules && (group.group === SupplierModuleGroupType.AwaitingCompletion || group.group === SupplierModuleGroupType.Rejected || group.group === SupplierModuleGroupType.Submitted))
                    return true;

                return false;
            });
        }

        private surveyQuestionOptions: SurveyQuestionOptions = new SurveyQuestionOptions(false, ResponseSetStatus.None);

        // https://stackoverflow.com/questions/43799416/how-to-mount-dynamically-single-file-component-in-vue-js
        // note typeof !
        private get selectedModuleComponent(): typeof Vue | null {
            if( this.supplierResponseSet == null) return MissingResponseSet;
            if (this.supplierResponseSet.status === ResponseSetStatus.None) return MissingResponseSet;
            if (this.supplierResponseSet.status === ResponseSetStatus.Legacy || this.supplierResponseSet.status === ResponseSetStatus.LegacyInProgress) return LegacyDocumentList;
            return SurveyPageComponent;
        }

        private get statusText(): string {
            if(this.supplierResponseSet == null) return "- - -";
            if(this.supplierResponseSet.status === ResponseSetStatus.None) return "- - -";
            if(this.supplierResponseSet.status === ResponseSetStatus.AwaitingCompletion) return "Awaiting completion";
            if(this.supplierResponseSet.status === ResponseSetStatus.Accepted && this.supplierResponseSet.expired) return "Expired";
            return ResponseSetStatus[this.supplierResponseSet.status]; // just get enum text
        }

        private get rejectReason(): string {
            if(this.supplierResponseSet == null) return "";
            return this.supplierResponseSet.rejectReason;
        }

        private get statusAlertCssClass(): string {
            let variant = "info";
            if (this.supplierResponseSet != null) {
                if (this.supplierResponseSet.status === ResponseSetStatus.Submitted) variant = "warning";
                if (this.supplierResponseSet.status === ResponseSetStatus.Accepted && !this.supplierResponseSet.expired) variant = "success";
                if (this.supplierResponseSet.status === ResponseSetStatus.Accepted && this.supplierResponseSet.expired) variant = "dark";
                if (this.supplierResponseSet.status === ResponseSetStatus.Rejected) variant = "danger";
            }
            return `alert alert-${variant}`;
        }

        private groupDescription(groupType: SupplierModuleGroupType): string {
            if (groupType === SupplierModuleGroupType.Accepted) return "Current";
            return utils.camelCaseAddSpaces(SupplierModuleGroupType[groupType] as string);
        }

        private groupBadgeVariant(groupType: SupplierModuleGroupType): string {
            let variant = "info";
            if (groupType === SupplierModuleGroupType.Submitted) variant = "warning";
            if (groupType === SupplierModuleGroupType.Accepted) variant = "success";
            if (groupType === SupplierModuleGroupType.Rejected) variant = "danger";
            if (groupType === SupplierModuleGroupType.Expired) variant = "danger";
            return variant;
        }

        private statusMenuItemCssClass(module: SupplierModule): CssClasses {
            const cssClass = { 
                active: module.id === this.selectedModuleID,
                'list-group-item-danger': module.expired || module.status === ResponseSetStatus.Rejected,
                'list-group-item-warning': !module.expired && module.status === ResponseSetStatus.Submitted,
                'list-group-item-success': module.status === ResponseSetStatus.Accepted && !module.expired,
                'list-group-item-secondary': module.status === ResponseSetStatus.Accepted && module.expired
            };
            return cssClass;
        }

        private shouldShowAvatar(supplierModule: SupplierModule): boolean {
            return supplierModule.status === ResponseSetStatus.Legacy || supplierModule.status === ResponseSetStatus.LegacyInProgress;
        }

        private responseSetIconVariant(module: SupplierModule): string {
            if (module.expired) return "secondary"
            if (module.status === ResponseSetStatus.Legacy) return "success";
            if (module.status === ResponseSetStatus.LegacyInProgress) return "warning";
            if (module.status === ResponseSetStatus.AwaitingCompletion) return "info";
            if (module.status === ResponseSetStatus.Submitted) return "warning";
            if (module.status === ResponseSetStatus.Rejected) return "danger";
            if (module.status === ResponseSetStatus.Accepted) return "success";
            return "dark";
        }

        private responseSetStatusText(module: SupplierModule): string {
            if (module.expired) return "Expired"
            if (module.status === ResponseSetStatus.Legacy) return "Legacy";
            if (module.status === ResponseSetStatus.LegacyInProgress) return "Legacy - In Progress";
            if (module.status === ResponseSetStatus.AwaitingCompletion) return "Awaiting completion";
            if (module.status === ResponseSetStatus.Submitted) return "Submitted";
            if (module.status === ResponseSetStatus.Rejected) return "Rejected";
            if (module.status === ResponseSetStatus.Accepted) return "Accepted";
            return "- - -";
        }

        private responseSetIconGlyphCss(module: SupplierModule): string {
            if (module.expired) return "fas fa-archive";
            if (module.status === ResponseSetStatus.Legacy) return "fas fa-file";
            if (module.status === ResponseSetStatus.LegacyInProgress) return "fas fa-tasks";
            if (module.status === ResponseSetStatus.AwaitingCompletion) return "fas fa-tasks";
            if (module.status === ResponseSetStatus.Submitted) return "fas fa-paper-plane";
            if (module.status === ResponseSetStatus.Rejected) return "fas fa-times";
            if (module.status === ResponseSetStatus.Accepted) return "fas fa-check";
            return "fas fa-question-circle";
        }

        private get guidanceDocumentFilename(): string {
            return this.module?.guideDocument?.originalFilename ?? "(null)";
        }

        private get guidanceIconUrl(): string {
            return utils.iconUrl(this.module?.guideDocument);
        }

        //
        // -- watchers
        //

        @Watch("isMyModules") 
        private onPageChanged() {
            this.module = null;
        }

        @Watch("module")
        async onModuleChanged() {
            if(!this.module || !this.module.id) {
                this.supplierResponseSet = null;
                return;
            }

            const responseSetData: ISupplierResponseSet | null = await apiClient.get(`api/SupplierResponse/SupplierZoneResponseSetLoad?id=${this.module.responseSetID}`);
            
            if (!responseSetData) {
                this.supplierResponseSet = null;
                this.surveyQuestionOptions.responseSetStatus = ResponseSetStatus.None;
            }
            else {          
                this.supplierResponseSet = new SupplierResponseSet(responseSetData);
                this.surveyQuestionOptions.responseSetStatus = responseSetData.status;
            }
        }

        //
        // -- events
        //

        // triggered when user submits, rejects or accepts (button on StatusSetter.vue)
        // (except here it will only be submit - see also Survey.vue)
        async onStatusChanged(args: IStatusChangedArguments) {
            console.log("#### supplierZone/Modules.vue - onStatusChanged ", args);
            if(this.supplierResponseSet == null) return;
            this.supplierResponseSet.status = args.status;
            this.supplierResponseSet.rejectReason = args.rejectReason || "";
            this.supplierResponseSet.declarationName = args.declaration?.name ?? null,
            this.supplierResponseSet.declarationJobRole = args.declaration?.jobRole ?? null
            this.supplierResponseSet.detailsCorrect = args.declaration?.detailsCorrect ?? null
            if(args.status === ResponseSetStatus.Accepted) {
                this.supplierResponseSet.activeFrom = args.activeFrom;
                this.supplierResponseSet.expires = args.expires;
            }
            this.surveyQuestionOptions.responseSetStatus = args.status;
            await apiClient.post("api/SupplierResponse/SaveResponseSet", this.supplierResponseSet);
            toastr.success("Status updated");
            await this.refresh();
        }

        //
        // -- methods
        //

        async downloadGuidance() {
            if(!this.module?.guideDocument) return;
            fileDownload.download(this.module.guideDocument);
        }

        // changes module which then triggers the watcher which does the do
        async selectModule(module: Module) {
            if(!this.isDirty) {
                this.module = module;
                return;        
            }
            const shouldChange: boolean = await this.$bvModal.msgBoxConfirm(`Do you want to go to the ${module.description} module now without saving changes?`, {
                title: "Abandon Changes?",
                okVariant: "warning",
                okTitle: "Yes, I don't care",
                cancelTitle: "No, leave me here",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (shouldChange) {
                // make dirty flag go away
                dirtyness.setClean({ model: {}, isDeep: false, excludeList: [] });
                this.module = module;
            }           
        }

        async refresh() {
            await this.getSupplierInfo();
            await this.refreshModules();
        }

        async getSupplierInfo() {
            const json: ISupplier = await apiClient.get(`api/supplier/load?id=${this.searchParameters.supplierID}`);
            this.supplier.update(json);
        }

        async refreshModules() {
            this.isWaiting = true;
            const responseData = await apiClient.post("api/module/supplierModules", this.searchParameters);
            this.moduleGroups = responseData.map((g: ISupplierModuleGroup) => new SupplierModuleGroup(g));
            this.isWaiting = false;
        }

    }
