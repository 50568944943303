import { TriState } from "./Enums";

export class ModuleSearchParameters {
    pageNumber: number = 0;
    searchText: string = "";
    id: number = 0;
    buyerID: number = 0;
    supplierID: number = 0;
    isForSupplierZone: boolean = false; // requires supplier ID to be set also
    isForBuyerZone: boolean = false; // requires buyer ID to be set also
    isMandatory: TriState = TriState.UseDefault;
    isDeleted: TriState = TriState.UseDefault;
}