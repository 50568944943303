import { Module } from "@/model/Module";
import { ResponseSetStatus } from "@/model/Enums";
import { Document as ModelDocument, IDocument } from "@/model/Document";
import { mapData } from "@/stuff/DataMapping";
import utils  from "@/stuff/Utils";

export interface ISupplierModule {
    expires: Date;
    status: ResponseSetStatus;

    id: number;
    displayOrder: number;
    description: string;
    isBuyerLevel: boolean;
    isMandatory: boolean;
    tradeIDs: Array<number>;
    scopeIDs: Array<number>;
    isDocument: boolean;
    isCoinsInformationSet: boolean;
    cdmCategoryID: number;
    guideDocumentID: string;
    documentIDs: Array<string>;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    exemptFromCompliance: boolean;
    responseSetID: number;
    confirmOnSubmission: boolean;

    guideDocument: IDocument;
    documents: Array<IDocument>;
    chaseEmails: Array<number>;
    rejectionChaseEmails: Array<number>;
    hasChaseEmails: boolean;
    rejectionAdminActionRequired: boolean;
    acceptedAdminActionRequired: boolean;
    exemptFromQA: boolean;
}

export class SupplierModule extends Module implements ISupplierModule {

    constructor(data?: ISupplierModule) {
        super(data);
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplierModule) {
        mapData(data, { 
            root: () => this,
            documents: () => new ModelDocument(),
            guideDocument: () => this.guideDocument
        });
    }

    expires: Date = new Date(utils.emptyDateValue);
    status: ResponseSetStatus = ResponseSetStatus.None;

    get expired(): boolean {
        return utils.hasDateValue(this.expires) && this.expires < new Date();
    }

    get showExpiryDate(): boolean {
        return utils.hasDateValue(this.expires) && (this.status == 1 || this.status == 5 || this.status == 6)
    }

    get expiresText(): string {
        return this.expires <= new Date() 
            ? `Expired ${utils.dateText(this.expires)}`
            : `Expires ${utils.dateText(this.expires)}`
    }

}