
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import utils from "@/stuff/Utils";
    import fileDownload from "@/stuff/FileDownload";
    import { SupplierResponseSet } from '@/model/SupplierResponseSet';
    import { Document as ModelDocument } from "@/model/Document";

    @Component({})
    export default class LegacyDocumentList extends Vue {

        @Prop({ required: true })
        private responseSet!: SupplierResponseSet;

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }
    }
